import React from 'react';
import { Typography } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { PlusSquareFilled } from '@ant-design/icons';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';

const bg = 'linear-gradient(266deg, #6EDFFB 0%, #B97EFC 46.95%, #5956E9 100%)';

const Card = styled('div')(() => ({
  height: 86,
  gap: '.8rem',
  width: '100%',
  background: bg,
  display: 'flex',
  cursor: 'pointer',
  padding: '1.6rem',
  alignItems: 'center',
  marginInline: 'auto',
  flexDirection: 'row',
  borderRadius: '.8rem',
  justifyContent: 'center',
  transition: 'opacity .3s ease-in',
  [`& > .${prefixCls}-typography`]: {
    color: 'white',
    fontWeight: 700,
  },
  '& > .anticon': {
    color: 'white',
    fontSize: '2.4rem',
  },
  '&:hover': {
    opacity: 0.8,
  },
}));

export const CreateFormScratchButton: React.FC<{
  from?: string;
  onClick: () => void;
}> = ({ onClick }) => {
  const posthog = useLocalPostHog();
  const [disabled, setDisabled] = React.useState(false);

  return (
    <Card
      onClick={() => {
        if (disabled) return;

        setDisabled(true);

        onClick();

        try {
          posthog?.capture(PosthogEvents.click_create_scratch);
        } catch (e) {
          console.error('Posthog error:', e);
        }
      }}
    >
      <PlusSquareFilled />
      <Typography.Text>Create from scratch</Typography.Text>
    </Card>
  );
};
